import { default as React } from 'react';
import { AppLayout } from '~/components/AppLayout';
import HeroSlides from '~/components/hero/hero-slides';
import { Loadable } from '~/components/loadable.tsx';
import { MainHeader } from '~/components/main-header/main-header';
import ProductList, {
  ProductsLoaderIndicator,
} from '~/components/product-list/product-list.tsx';
import { SidebarRight } from '~/components/sidebar';
import { SidebarDefault } from '~/components/sidebar/sidebar-default.tsx';
import { Meta } from '~/state/router/metaTags.tsx';

const images = [
  { src: '/banners/zazbanner1.webp', alt: '' },
  { src: '/banners/zazbanner2.webp', alt: '' },
  { src: '/banners/zazbanner1.webp', alt: '' },
  { src: '/banners/zazbanner2.webp', alt: '' },
  // { src: 'zazbanner1', alt: '' },
  // { src: 'zazbanner1', alt: '' },
  // { src: 'zazbanner1', alt: '' },
];

export default function Home() {
  return (
    <AppLayout
      navbar={<MainHeader />}
      hero={<HeroSlides images={images} />}
      left={<SidebarDefault />}
      right={<SidebarRight />}
      main={
        <Loadable loadingIndicator={<ProductsLoaderIndicator />}>
          <ProductList
            infinite
            method={'getCollectionProducts'}
            variables={{ collection: 'hidden-featured-home', first: 50 }}
            title={null}
          />
        </Loadable>
      }
    />
  );
}

function Metadata() {
  return (
    <Meta
      value={{
        title: 'ZAZCART | Início',
        description: '',
      }}
    />
  );
}
